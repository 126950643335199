import React, { useEffect, useState } from 'react'
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios"; // Import axios library
import { Button } from '@chakra-ui/react';


const StripePay = () => {

  const [name, setname] = useState(null);
  const [email, setemail] = useState(null);
  const [phone, setphone] = useState(null);
  const [finalAmount, setFinalAmount] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [cart, setCart] = useState([]);
  const [discount, setDiscount] = useState(0);


  let getDraft = async () => {
    let userToken = localStorage.getItem("userToken");
    try {
      let response = await axios.get("https://api.digiblocks.tech/order/draft", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      // console.log(response.data)
      // Process the response or return data as needed
      setShippingFee(response.data[0]?.orderdetails?.orderDetails?.shippingCharges);
      setCart(response.data[0]?.orderdetails?.orderDetails?.customerInfo?.checkoutDetails?.cartProducts);
      setname(response.data[0]?.orderdetails?.orderDetails?.customerInfo?.billing?.customerName);
      setemail(response.data[0]?.orderdetails?.orderDetails?.customerInfo?.billing?.email);
      setphone(response.data[0]?.orderdetails?.orderDetails?.customerInfo?.billing?.phone);
      setFinalAmount(response.data[0]?.orderdetails?.finalAmount)
    } catch (error) {
      console.error('Error fetching drafts:', error);
      // Handle errors here
    }
  }

  useEffect(() => {
    getDraft();
  }, [])

  let paymentFunction = async () => {
    try {
      const stripe = await loadStripe(
        "pk_test_51P9067SIrbkdxJsZ3xnV0DVCXc4BlOGOMdYA4IagvhzulmEmreND07nvKbGadD4GXGKd6qeUMSwvGy4o7Bahl9Od00yIAMCpL3"
      );
      const body = { products: cart, shippingFee }; // Corrected typo in "products"
      const headers = { "Content-Type": "application/json" }; // Corrected header name
      const response = await axios.post("http://localhost:5501/create-checkout-session", body, {
        headers: headers,
      });
      const session = response.data; // No need to parse JSON, axios does it automatically

      const result = await stripe.redirectToCheckout({
        sessionId: session.id
      });

      // if any errors are encountered
      if (result.error) {
        // console.log(result.error);
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
    }
  };

  return (
    <>
      <Button onClick={paymentFunction}>Pay</Button>
    </>


  );
}

export default StripePay
