import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminNav from './AdminNav';
import { ChakraProvider, Box, Flex, Heading, Text, Spinner, HStack } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { FaCube, FaShippingFast, FaEdit, FaList, FaTags, FaEnvelope, FaTicketAlt, FaUsers, FaClipboardList } from 'react-icons/fa';

const fetchData = async (url, filter) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error fetching data from ${url}: ${response.status}`);
    }
    const data = await response.json();
    if (filter) {
      return data.filter(filter).length;
    }
    return data.length;
  } catch (error) {
    throw new Error(`Error loading data: ${error.message}`);
  }
};

const DashboardBox = ({ title, apiURL, icon, color, routePath, filter }) => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery([apiURL, filter], () => fetchData(apiURL, filter));

  const iconSize = "2.5rem";

  const handleClick = () => {
    navigate(routePath);
  };

  return (
    <Box
      p={4}
      mb={4}
      width={{ base: "100%", sm: "48%", md: "31%", lg: "23%" }}
      height="200px"
      borderRadius="lg"
      boxShadow="lg"
      color="white"
      textAlign="center"
      bgGradient={`linear(to-br, ${color}.400, ${color}.500)`}
      cursor="pointer"
      onClick={handleClick}
    >
      <Text fontSize="lg" mb={2} color="white">
        {icon && React.createElement(icon, { size: iconSize, color: 'white', mb: 2 })}
      </Text>
      <Heading fontSize="xl" mb={2}>
        {title}
      </Heading>
      {isLoading && <Spinner color="white" size="md" />}
      {isError && <Text color="red.500">{isError.message}</Text>}
      {data && <Text fontSize="3xl">{data}</Text>}
    </Box>
  );
};

const AdminDashboard = () => {
  return (
    <ChakraProvider>
      <Flex direction={["column", "column", "row"]}>
        <AdminNav />
        <Box width={{ base: "100%", md: "85%", lg: "80%" }} m={"auto"} ml={{ base: 0, md: 0, lg: "18%" }}>
          <Heading mb={4} mt={4}>📊 Admin Dashboard</Heading>
          <HStack spacing={4} justifyContent="space-between" flexWrap="wrap">
            <DashboardBox title="Total Products" apiURL="https://api.digiblocks.tech/products/" icon={FaCube} color="teal" routePath="/manager/listing" />
            <DashboardBox title="Shipping Profiles" apiURL="https://api.digiblocks.tech/shipping-profiles" icon={FaShippingFast} color="purple" routePath="/manager/allshippingprofile" />
            <DashboardBox title="Drafts Products" apiURL="https://api.digiblocks.tech/drafts/products/" icon={FaEdit} color="orange" routePath="/manager/alldrafts" />
            <DashboardBox title="B2B Enquiries" apiURL="https://api.digiblocks.tech/b2bInquiry" icon={FaList} color="cyan" routePath="/manager/b2b" />
            <DashboardBox title="Total Categories" apiURL="https://api.digiblocks.tech/adminsetting/categories" icon={FaTags} color="pink" routePath="/manager/settings" />
            <DashboardBox title="Contact Queries" apiURL="https://api.digiblocks.tech/contact" icon={FaEnvelope} color="green" routePath="/manager/integration" />
            <DashboardBox title="Discount Coupons" apiURL="https://api.digiblocks.tech/discount/coupons" icon={FaTicketAlt} color="red" routePath="/manager/marketing" />
            <DashboardBox title="Promo Codes" apiURL="https://api.digiblocks.tech/promo-codes" icon={FaTicketAlt} color="green" routePath="/manager/marketing" />
            <DashboardBox title="News Letter Subscribers" apiURL="https://api.digiblocks.tech/subscribe/subscribers" icon={FaClipboardList} color="blue" routePath="/manager/subscribers" />
            <DashboardBox title="Registered Users" apiURL="https://api.digiblocks.tech/getallusers" icon={FaUsers} color="yellow" routePath="/manager/users" />
            <DashboardBox title="Total Orders" apiURL="https://api.digiblocks.tech/allOrders/" icon={FaCube} color="teal" routePath="/manager/orders" filter={(order) => order.isPaid === true} />
            <DashboardBox title="Messages" apiURL="https://api.digiblocks.tech/messages/getallusers" icon={FaEnvelope} color="red" routePath="/manager/messages" />
          </HStack>
        </Box>
      </Flex>
    </ChakraProvider>
  );
};

export default AdminDashboard;
