import React, { useState, useEffect } from 'react';
import {
    Flex,
    Text,
    Input,
    Tag,
    Button,
    TagLabel,
    TagCloseButton,
    IconButton,
    useToast,
    Switch,
    Box,
} from '@chakra-ui/react';
import { FaEdit, FaSave } from 'react-icons/fa';

const UnitSettings = () => {
    const [units, setUnits] = useState([]);
    const [newUnit, setNewUnit] = useState('');
    const [editingUnit, setEditingUnit] = useState(null);
    const [editedValue, setEditedValue] = useState('');
    const [showAllUnits, setShowAllUnits] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const toast = useToast();

    useEffect(() => {
        fetchUnits();
    }, []);

    const fetchUnits = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/units');
            const data = await response.json();
            setUnits(data.map(unit => ({ ...unit })));
        } catch (error) {
            console.error('Error fetching units:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewUnit(e.target.value);
        setIsButtonDisabled(!e.target.value.trim()); // Disable the button if the input is empty or contains only whitespace
    };

    const handleAddUnit = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/units', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ units: newUnit }),
            });

            if (response.ok) {
                setNewUnit('');
                setIsButtonDisabled(true); // Disable the button after adding a unit
                fetchUnits();
                showToast('Unit added successfully', 'success');
            } else {
                console.error('Error adding unit:', response.statusText);
                showToast('Error adding unit', 'error');
            }
        } catch (error) {
            console.error('Error adding unit:', error);
            showToast('Error adding unit', 'error');
        }
    };

    const handleDeleteUnit = async (unit) => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/units/${unit._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchUnits();
                showToast('Unit deleted successfully', 'success');
            } else {
                console.error('Error deleting unit:', response.statusText);
                showToast('Error deleting unit', 'error');
            }
        } catch (error) {
            console.error('Error deleting unit:', error);
            showToast('Error deleting unit', 'error');
        }
    };

    const handleEditUnit = (unit) => {
        setEditingUnit(unit._id);
        setEditedValue(unit.units);
    };

    const handleSaveEdit = async (unit) => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/units/${unit._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ units: editedValue }),
            });

            if (response.ok) {
                setEditingUnit(null);
                fetchUnits();
                showToast('Unit updated successfully', 'success');
            } else {
                console.error('Error updating unit:', response.statusText);
                showToast('Error updating unit', 'error');
            }
        } catch (error) {
            console.error('Error updating unit:', error);
            showToast('Error updating unit', 'error');
        }
    };

    const showToast = (message, status) => {
        toast({
            title: message,
            status,
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box border={"1px solid teal"} bg={"white"} borderRadius={"3xl"} p={4} my={4}>
            <Text fontSize="2xl" mb={4} fontWeight="bold">Manage Units</Text>
            <Flex justifyContent="space-between" direction={["column", "column", "row"]} mb={4}>
                <Flex>
                    <Input
                        width={"100%"}
                        placeholder="Enter a new unit"
                        value={newUnit}
                        borderRadius={"2xl"}
                        onChange={handleInputChange}
                    />
                    <Button
                        ml={2}
                        borderRadius={"2xl"}
                        onClick={handleAddUnit}
                        colorScheme="green"
                        isDisabled={isButtonDisabled}
                    >
                        Add
                    </Button>
                </Flex>
                <Flex>
                    <Switch
                        colorScheme="green"
                        size="lg"
                        mt={["10px", "10px", "0px"]}
                        m={["auto", "", ""]}
                        fontWeight={"semibold"}
                        isChecked={showAllUnits}
                        onChange={() => setShowAllUnits(!showAllUnits)}
                    >
                        Show All Units
                    </Switch>
                </Flex>
            </Flex>

            <Flex flexWrap="wrap">
                {units.map((unit) => (
                    <Tag
                        key={unit._id}
                        m={1}
                        size="lg"
                        variant="solid"
                        colorScheme="blue"
                        borderRadius="full"
                        bg={"darkblue"}
                        _hover={{ cursor: 'pointer', opacity: 0.8 }}
                        display={showAllUnits ? 'flex' : editingUnit === unit._id ? 'flex' : 'none'}
                    >
                        {editingUnit === unit._id ? (
                            <>
                                <Input
                                    value={editedValue}
                                    onChange={(e) => setEditedValue(e.target.value)}
                                    borderRadius={"full"}
                                    marginRight={2}
                                />
                                <IconButton
                                    bg={"none"}
                                    icon={<FaSave />}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleSaveEdit(unit)}
                                    borderRadius={"full"}
                                />
                                <TagCloseButton color="white" onClick={() => setEditingUnit(null)} />
                            </>
                        ) : (
                            <>
                                <TagLabel>{unit.units}</TagLabel>
                                <TagCloseButton color="white" onClick={() => handleDeleteUnit(unit)} />
                                <IconButton
                                    icon={<FaEdit />}
                                    bg={"none"}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleEditUnit(unit)}
                                    borderRadius={"full"}
                                    ml={2}
                                />
                            </>
                        )}
                    </Tag>
                ))}
            </Flex>
        </Box>
    );
};

export default UnitSettings;
