import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Text,
    Input,
    Tag,
    Button,
    TagLabel,
    TagCloseButton,
    IconButton,
    useToast,
    Switch,
} from '@chakra-ui/react';
import { FaEdit, FaSave } from 'react-icons/fa';

const TagsSettings = () => {
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [editingTag, setEditingTag] = useState(null);
    const [editedValue, setEditedValue] = useState('');
    const [showAllTags, setShowAllTags] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const toast = useToast();

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/tags');
            const data = await response.json();
            setTags(data.map(tag => ({ ...tag })));
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewTag(e.target.value);
        setIsButtonDisabled(!e.target.value.trim()); // Disable the button if the input is empty or contains only whitespace
    };

    const handleAddTag = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/tags', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tags: newTag }),
            });

            if (response.ok) {
                setNewTag('');
                setIsButtonDisabled(true); // Disable the button after adding a tag
                fetchTags();
                showToast('Tag added successfully', 'success');
            } else {
                console.error('Error adding tag:', response.statusText);
                showToast('Error adding tag', 'error');
            }
        } catch (error) {
            console.error('Error adding tag:', error);
            showToast('Error adding tag', 'error');
        }
    };

    const handleDeleteTag = async (tag) => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/tags/${tag._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchTags();
                showToast('Tag deleted successfully', 'success');
            } else {
                console.error('Error deleting tag:', response.statusText);
                showToast('Error deleting tag', 'error');
            }
        } catch (error) {
            console.error('Error deleting tag:', error);
            showToast('Error deleting tag', 'error');
        }
    };

    const handleEditTag = (tag) => {
        setEditingTag(tag._id);
        setEditedValue(tag.tags);
    };

    const handleSaveEdit = async (tag) => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/tags/${tag._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tags: editedValue }),
            });

            if (response.ok) {
                setEditingTag(null);
                fetchTags();
                showToast('Tag updated successfully', 'success');
            } else {
                console.error('Error updating tag:', response.statusText);
                showToast('Error updating tag', 'error');
            }
        } catch (error) {
            console.error('Error updating tag:', error);
            showToast('Error updating tag', 'error');
        }
    };

    const showToast = (message, status) => {
        toast({
            title: message,
            status,
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box border={"1px solid teal"} bg={"white"} borderRadius={"3xl"} p={4} my={4}>
            <Text fontSize="2xl" mb={4} fontWeight="bold">Manage Tags</Text>
            <Flex justifyContent="space-between" direction={["column", "column", "row"]} mb={4}>
                <Flex>
                    <Input
                        width={"100%"}
                        placeholder="Enter a new tag"
                        value={newTag}
                        borderRadius={"2xl"}
                        onChange={handleInputChange}
                    />
                    <Button
                        ml={2}
                        borderRadius={"2xl"}
                        onClick={handleAddTag}
                        colorScheme="green"
                        isDisabled={isButtonDisabled}
                    >
                        Add
                    </Button>
                </Flex>
                <Flex>
                    <Switch
                        colorScheme="green"
                        size="lg"
                        mt={["10px", "10px", "0px"]}
                        m={["auto", "", ""]}
                        fontWeight={"semibold"}
                        isChecked={showAllTags}
                        onChange={() => setShowAllTags(!showAllTags)}
                    >
                        Show All Tags
                    </Switch>
                </Flex>
            </Flex>

            <Flex flexWrap="wrap">
                {tags.map((tag) => (
                    <Tag
                        key={tag._id}
                        m={1}
                        size="lg"
                        variant="solid"
                        colorScheme="blue"
                        borderRadius="full"
                        bg={"darkblue"}
                        _hover={{ cursor: 'pointer', opacity: 0.8 }}
                        display={showAllTags ? 'flex' : editingTag === tag._id ? 'flex' : 'none'}
                    >
                        {editingTag === tag._id ? (
                            <>
                                <Input
                                    value={editedValue}
                                    onChange={(e) => setEditedValue(e.target.value)}
                                    borderRadius={"full"}
                                    marginRight={2}
                                />
                                <IconButton
                                    bg={"none"}
                                    icon={<FaSave />}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleSaveEdit(tag)}
                                    borderRadius={"full"}
                                />
                                <TagCloseButton color="white" onClick={() => setEditingTag(null)} />
                            </>
                        ) : (
                            <>
                                <TagLabel>{tag.tags}</TagLabel>
                                <TagCloseButton color="white" onClick={() => handleDeleteTag(tag)} />
                                <IconButton
                                    icon={<FaEdit />}
                                    bg={"none"}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleEditTag(tag)}
                                    borderRadius={"full"}
                                    ml={2}
                                />
                            </>
                        )}
                    </Tag>
                ))}
            </Flex>
        </Box>
    );
};

export default TagsSettings;
