import React, { useState, useEffect } from 'react';
import {
    Flex,
    Text,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Tag,
    Button,
    TagLabel,
    TagCloseButton,
    IconButton,
    useToast,
    Switch,
    Box,
    Select,
} from '@chakra-ui/react';
import { FaEdit, FaSave } from 'react-icons/fa';
import axios from 'axios';

const SubCategorySettings = () => {
    const [subcategories, setSubcategories] = useState([]);
    const [category, setCategory] = useState([]);
    const [currCategory, setCurrCategory] = useState('');
    const [viewAllSubCategories, setViewAllSubCategories] = useState([]);
    const [newSubcategory, setNewSubcategory] = useState('');
    const [editingSubcategory, setEditingSubcategory] = useState(null);
    const [editedValue, setEditedValue] = useState('');
    const [showAllSubcategories, setShowAllSubcategories] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const toast = useToast();

    useEffect(() => {
        fetchSubcategories();
        getcategory();
    }, []);

    let getcategory = async () => {
        let data = await axios.get('https://api.digiblocks.tech/adminsetting/categories');
        setCategory(data.data);
    };

    const fetchSubcategories = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/subcategories');
            const data = await response.json();
            setSubcategories(data.map(subcategory => ({ ...subcategory })));
            setViewAllSubCategories(data);
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    const handleInputChange = e => {
        setNewSubcategory(e.target.value);
        setIsButtonDisabled(!e.target.value.trim());
    };

    const handleAddSubcategory = async () => {
        if (currCategory === '') {
            alert('Please select a category');
        } else {
            try {
                const response = await fetch('https://api.digiblocks.tech/adminsetting/subcategories', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ subcategories: newSubcategory, category: currCategory }),
                });

                if (response.ok) {
                    setNewSubcategory('');
                    setIsButtonDisabled(true);
                    fetchSubcategories();
                    showToast('Subcategory added successfully', 'success');
                } else {
                    console.error('Error adding subcategory:', response.statusText);
                    showToast('Error adding subcategory', 'error');
                }
            } catch (error) {
                console.error('Error adding subcategory:', error);
                showToast('Error adding subcategory', 'error');
            }
        }
    };

    const handleDeleteSubcategory = async subcategory => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/subcategories/${subcategory._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchSubcategories();
                showToast('Subcategory deleted successfully', 'success');
            } else {
                console.error('Error deleting subcategory:', response.statusText);
                showToast('Error deleting subcategory', 'error');
            }
        } catch (error) {
            console.error('Error deleting subcategory:', error);
            showToast('Error deleting subcategory', 'error');
        }
    };

    const handleEditSubcategory = subcategory => {
        setEditingSubcategory(subcategory._id);
        setEditedValue(subcategory.subcategories);
    };

    let handleSelectChange = e => {
        setCurrCategory(e.target.value);
    };

    const handleSaveEdit = async subcategory => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/subcategories/${subcategory._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subcategories: editedValue }),
            });

            if (response.ok) {
                setEditingSubcategory(null);
                fetchSubcategories();
                showToast('Subcategory updated successfully', 'success');
            } else {
                console.error('Error updating subcategory:', response.statusText);
                showToast('Error updating subcategory', 'error');
            }
        } catch (error) {
            console.error('Error updating subcategory:', error);
            showToast('Error updating subcategory', 'error');
        }
    };

    const showToast = (message, status) => {
        toast({
            title: message,
            status,
            duration: 3000,
            isClosable: true,
        });
    };

    const groupSubcategoriesByCategory = () => {
        const groupedSubcategories = {};

        viewAllSubCategories.forEach(subcategory => {
            const category = subcategory.category;
            if (!groupedSubcategories[category]) {
                groupedSubcategories[category] = [];
            }

            groupedSubcategories[category].push(subcategory);
        });

        return groupedSubcategories;
    };

    const renderSubcategoryGroups = () => {
        const groupedSubcategories = groupSubcategoriesByCategory();
        return (
            <Table display={showAllSubcategories ? 'block' : 'none'} variant="simple" bg={"white"}>
                <Thead>
                    <Tr>
                        <Th>Category</Th>
                        <Th>Subcategories</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.entries(groupedSubcategories).map(([category, subcategories]) => (
                        <Tr key={category}>
                            <Td fontWeight={"semibold"}>{category}</Td>
                            <Td>
                                <Flex direction={"row"}  flexWrap={"wrap"}>
                                    {subcategories.map(subcategory => (
                                        <Tag
                                            key={subcategory._id}
                                            m={1}
                                            size="md"
                                            variant="solid"
                                            colorScheme="blue"
                                            borderRadius="full"
                                            bg={"darkblue"}
                                            width={"30%"}
                                            _hover={{ cursor: 'pointer', opacity: 0.8 }}
                                            
                                        >
                                            {editingSubcategory === subcategory._id ? (
                                                <>
                                                    <Input
                                                        value={editedValue}
                                                        onChange={e => setEditedValue(e.target.value)}
                                                        borderRadius={"full"}
                                                        marginRight={2}
                                                    />
                                                    <IconButton
                                                        bg={"none"}
                                                        icon={<FaSave />}
                                                        color="white"
                                                        colorScheme="blue"
                                                        onClick={() => handleSaveEdit(subcategory)}
                                                        borderRadius={"full"}
                                                    />
                                                    <TagCloseButton color="white" onClick={() => setEditingSubcategory(null)} />
                                                </>
                                            ) : (
                                                <>
                                                    <TagLabel>{subcategory.subcategories}</TagLabel>
                                                    <TagCloseButton color="white" onClick={() => handleDeleteSubcategory(subcategory)} />
                                                    <IconButton
                                                        icon={<FaEdit />}
                                                        bg={"none"}
                                                        color="white"
                                                        colorScheme="blue"
                                                        onClick={() => handleEditSubcategory(subcategory)}
                                                        borderRadius={"full"}
                                                        ml={2}
                                                    />
                                                </>
                                            )}
                                        </Tag>
                                    ))}</Flex>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        );
    };

    return (
        <div>
            <Box border={"1px solid teal"} bg={"white"} borderRadius={"3xl"} p={4} my={4}>
                <Text fontSize="2xl" mb={4} fontWeight="bold">Manage Sub-Categories</Text>
                <Flex justifyContent="space-between" direction={["column", "column", "row"]} mb={4}>
                    <Flex>
                        <Input
                            width={"100%"}
                            placeholder="Enter a new Sub-category"
                            value={newSubcategory}
                            borderRadius={"2xl"}
                            onChange={handleInputChange}
                        />
                        <Select onChange={handleSelectChange}>
                            <option value={""}>Select</option>
                            {category.length > 0 && category.map(e => (
                                <option key={e.categories} value={e.categories}>{e.categories}</option>
                            ))}
                        </Select>
                        <Button
                            ml={2}
                            borderRadius={"2xl"}
                            onClick={handleAddSubcategory}
                            colorScheme="green"
                            isDisabled={isButtonDisabled}
                        >
                            Add
                        </Button>
                    </Flex>
                    <Flex>
                        <Switch
                            colorScheme="green"
                            size="lg"
                            mt={["10px", "10px", "0px"]}
                            m={["auto", "", ""]}
                            fontWeight={"semibold"}
                            isChecked={showAllSubcategories}
                            onChange={() => setShowAllSubcategories(!showAllSubcategories)}
                        >
                            Show All Sub-categories
                        </Switch>
                    </Flex>
                </Flex>
            </Box>
            {renderSubcategoryGroups()}
        </div>
    );
};

export default SubCategorySettings;
