import React from 'react';
import {
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react';
import AdminNav from './AdminNav';
import ColorSettings from './ColorSetting';
import TagsSettings from './TagsSettings';
import UnitSettings from './UnitsSettings';
import CategorySettings from './CategorySetting';
import SubCategorySettings from './SubCategorySettings';
import CourierSettings from './CourierSetting';


const Settings = () => {
  return (
    <>
      <Flex direction={["column", "column", "row"]} p={4}>
        <AdminNav />
        <Box bg={"lightgray"} ml={["0px", "0px", "15%"]} px={8} w="100%">
          <Box border={"1px solid teal"} bg={"white"} mb={4} p={6} borderRadius={"20px"}><Heading as={"h3"}>Settings</Heading></Box>
          <TagsSettings />
          <ColorSettings />
          <UnitSettings />
          <CategorySettings />
          <SubCategorySettings />
          <CourierSettings/>
        </Box>
      </Flex>
    </>
  );
};

export default Settings;
