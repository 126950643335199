import React, { useContext } from 'react';
import {
  Box,
  VStack,
  Link,
  Text,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Button,
  Image,
  useToast,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { BiSolidCoupon } from 'react-icons/bi';
import { FaEnvelope, FaShoppingCart, FaChartLine, FaCog, FaShippingFast } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/knitsilk black logo.png';
import { IoLogOutOutline } from 'react-icons/io5';
import { AppContext } from '../../Context/AppContext';
import { FaUser } from 'react-icons/fa6';

const AdminNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const { setAdminAuth, refresh, setRefresh } = useContext(AppContext);

  const handleLogout = () => {
    setAdminAuth(false);
    sessionStorage.removeItem('adminstatus');
    setRefresh(!refresh);

    toast({
      title: 'Logout Successful',
      description: 'You have been successfully logged out.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <>
      <Box
        w={["0", "15%"]}
        h="100%"
        position={"fixed"}
        p="4"
        color="teal"
        fontWeight={"bold"}
        textAlign="center"
        display={["none", "none", "block"]}
        fontSize={"15px"}
        boxShadow={"0 0 3px gray"}
      >

        <Flex gap="2" direction={"column"} overflowY={"auto"} >
          <Box w={"100%"} mb={4} >
            <Image src={logo} alt="Uploaded" objectFit={"fill"}></Image>
          </Box>
          <Button onClick={() => {
            navigate("/manager/dashboard")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex textAlign={"left"} alignItems="center" justifyContent="center">
              <Text m={0} >Dashboard</Text>
            </Flex>
          </Button>
          <Button onClick={() => {
            navigate("/manager/listing")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex alignItems="left" justifyContent="center">
              <Text m={0}>Listing</Text>
            </Flex>
          </Button>
          <Button onClick={() => {
            navigate("/manager/messages")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex gap="10px" alignItems="center" justifyContent="center">
              <Text m={0} >Messages</Text>
              <FaEnvelope />
            </Flex>
          </Button>

          <Button onClick={() => {
            navigate("/manager/orders")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex gap="10px" alignItems="center" justifyContent="center">
              <Text m={0} >Orders & Delivery</Text>
              <FaShoppingCart />
            </Flex>

          </Button>

          <Button onClick={() => {
            navigate("/manager/stats")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex gap="10px" alignItems="center" justifyContent="center">
              <Text m={0} >Blogs</Text>
              <FaChartLine />
            </Flex>

          </Button>

          <Button onClick={() => {
            navigate("/manager/marketing")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex gap="10px" alignItems="center" justifyContent="center">
              <Text m={0} >Marketing</Text>
              <BiSolidCoupon />
            </Flex>

          </Button>

          <Button onClick={() => {
            navigate("/manager/integration")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex gap="10px" alignItems="center" justifyContent="center">
              <Text m={0} >Contact Queries</Text>
              <BiSolidCoupon />
            </Flex>
          </Button>

          <Button onClick={() => {
            navigate("/manager/settings")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex gap="10px" alignItems="center" justifyContent="center">
              <Text m={0} >Settings</Text>
              <FaCog />
            </Flex>
          </Button>
          <Button onClick={() => {
            navigate("/manager/allshippingprofile")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex gap="10px" alignItems="center" justifyContent="center">
              <Text m={0} >Shipping Profile</Text>
              <FaShippingFast />
            </Flex>
          </Button>
          <Button onClick={() => {
            navigate("/manager/createuser")
          }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
            <Flex gap="10px" alignItems="center" justifyContent="center">
              <Text m={0} >Create user</Text>
              <FaUser />
            </Flex>
          </Button>
          <Button variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }} onClick={() => {
            handleLogout();
          }}>
            <Flex gap="10px" alignItems="center" justifyContent="center" color="red">
              <IoLogOutOutline />
              <Text m={0}>Logout</Text>
            </Flex>
          </Button>


        </Flex>
      </Box>
      <Box >
        <Flex alignItems={"center"} p={2} bg={"rgb(111,168,47)"} display={["flex", "flex", "none"]} justifyContent={"space-between"} px={"20px"}>
          <Box w={"40%"} >
            <Image src={logo} alt="Uploaded" objectFit={"fill"}></Image>
          </Box>
          <HamburgerIcon onClick={onOpen} color={"white"} fontSize={"2xl"} />
        </Flex>
        {/* Drawer for Small screens */}
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <VStack spacing="4" align="stretch"  >
                <Box w={"100%"} mb={4} >
                  <Image src={logo} alt="Uploaded" objectFit={"fill"}></Image>
                </Box>
                <Button onClick={() => {
                  navigate("/manager/dashboard")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex alignItems="center" justifyContent="center">
                    <Text m={0} >Dashboard</Text>
                  </Flex>
                </Button>
                <Button onClick={() => {
                  navigate("/manager/listing")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex alignItems="center" justifyContent="center">
                    <Text m={0}>Listing</Text>
                  </Flex>
                </Button>
                <Button onClick={() => {
                  navigate("/manager/messages")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center">
                    <Text m={0} >Messages</Text>
                    <FaEnvelope />
                  </Flex>
                </Button>

                <Button onClick={() => {
                  navigate("/manager/orders")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center">
                    <Text m={0} >Orders & Delivery</Text>
                    <FaShoppingCart />
                  </Flex>

                </Button>

                <Button onClick={() => {
                  navigate("/manager/stats")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center">
                    <Text m={0} >Blogs</Text>
                    <FaChartLine />
                  </Flex>

                </Button>

                <Button onClick={() => {
                  navigate("/manager/marketing")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center">
                    <Text m={0} >Marketing</Text>
                    <BiSolidCoupon />
                  </Flex>

                </Button>

                <Button onClick={() => {
                  navigate("/manager/integration")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center">
                    <Text m={0} >Contact Queries</Text>
                    <BiSolidCoupon />
                  </Flex>
                </Button>

                <Button onClick={() => {
                  navigate("/manager/settings")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center">
                    <Text m={0} >Settings</Text>
                    <FaCog />
                  </Flex>
                </Button>
                <Button onClick={() => {
                  navigate("/manager/allshippingprofile")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center">
                    <Text m={0} >Shipping Profile</Text>
                    <FaShippingFast />
                  </Flex>
                </Button>
                <Button onClick={() => {
                  navigate("/manager/createuser")
                }} variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center">
                    <Text m={0} >Create user</Text>
                    <FaUser />
                  </Flex>
                </Button>
                <Button variant={"outline"} w={"100%"} p={2} as={Link} textDecoration="none" _hover={{ textDecor: 'none' }} onClick={() => {
                  handleLogout();
                }}>
                  <Flex gap="10px" alignItems="center" justifyContent="center" color="red">
                    <IoLogOutOutline />
                    <Text m={0}>Logout</Text>
                  </Flex>
                </Button>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

      </Box>
    </>
  );
};

export default AdminNav;
