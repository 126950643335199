import React, { useState, useEffect } from 'react';
import {
    Flex,
    Text,
    Input,
    Tag,
    Button,
    TagLabel,
    TagCloseButton,
    IconButton,
    useToast,
    Switch,
    Box,
} from '@chakra-ui/react';
import { FaEdit, FaSave } from 'react-icons/fa';

const CategorySettings = () => {
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [editedValue, setEditedValue] = useState('');
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const toast = useToast();
    let userRole = sessionStorage.getItem("admintype");

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/categories');
            const data = await response.json();
            setCategories(data.map(category => ({ ...category })));
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewCategory(e.target.value);
        setIsButtonDisabled(!e.target.value.trim());
    };

    const handleAddCategory = async () => {
        if (userRole !== "admin") {
            showToast('You are not authorized to add a category. Please ask your manager for access.', 'warning');
            return;
        }

        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/categories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ categories: newCategory }),
            });

            if (response.ok) {
                setNewCategory('');
                setIsButtonDisabled(true); // Disable the button after adding a category
                fetchCategories();
                showToast('Category added successfully', 'success');
            } else {
                console.error('Error adding category:', response.statusText);
                showToast('Error adding category', 'error');
            }
        } catch (error) {
            console.error('Error adding category:', error);
            showToast('Error adding category', 'error');
        }
    };

    const handleDeleteCategory = async (category) => {
        if (userRole !== "admin") {
            showToast('You are not authorized to delete a category. Please ask your manager for access.', 'warning');
            return;
        }
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/categories/${category._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchCategories();
                showToast('Category deleted successfully', 'success');
            } else {
                console.error('Error deleting category:', response.statusText);
                showToast('Error deleting category', 'error');
            }
        } catch (error) {
            console.error('Error deleting category:', error);
            showToast('Error deleting category', 'error');
        }
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category._id);
        setEditedValue(category.categories);
    };

    const handleSaveEdit = async (category) => {
        if (userRole !== "admin") {
            showToast('You are not authorized to edit a category. Please ask your manager for access.', 'warning');
            return;
        }
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/categories/${category._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ categories: editedValue }),
            });

            if (response.ok) {
                setEditingCategory(null);
                fetchCategories();
                showToast('Category updated successfully', 'success');
            } else {
                console.error('Error updating category:', response.statusText);
                showToast('Error updating category', 'error');
            }
        } catch (error) {
            console.error('Error updating category:', error);
            showToast('Error updating category', 'error');
        }
    };

    const showToast = (message, status) => {
        toast({
            title: message,
            status,
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box border={"1px solid teal"} bg={"white"} borderRadius={"3xl"} p={4} my={4}>
            <Text fontSize="2xl" mb={4} fontWeight="bold">Manage Categories</Text>
            <Flex justifyContent="space-between" direction={["column", "column", "row"]} mb={4}>
                <Flex>
                    <Input
                        width={"100%"}
                        placeholder="Enter a new category"
                        value={newCategory}
                        borderRadius={"2xl"}
                        onChange={handleInputChange}
                    />
                    <Button
                        ml={2}
                        borderRadius={"2xl"}
                        onClick={handleAddCategory}
                        colorScheme="green"
                        isDisabled={isButtonDisabled}
                    >
                        Add
                    </Button>
                </Flex>
                <Flex>
                    <Switch
                        colorScheme="green"
                        size="lg"
                        mt={["10px", "10px", "0px"]}
                        m={["auto", "", ""]}
                        fontWeight={"semibold"}
                        isChecked={showAllCategories}
                        onChange={() => setShowAllCategories(!showAllCategories)}
                    >
                        Show All categories
                    </Switch>
                </Flex>
            </Flex>

            <Flex flexWrap="wrap">
                {categories.map((category) => (
                    <Tag
                        key={category._id}
                        m={1}
                        size="lg"
                        variant="solid"
                        colorScheme="blue"
                        borderRadius="full"
                        bg={"darkblue"}
                        _hover={{ cursor: 'pointer', opacity: 0.8 }}
                        display={showAllCategories ? 'flex' : editingCategory === category._id ? 'flex' : 'none'}
                    >
                        {editingCategory === category._id ? (
                            <>
                                <Input
                                    value={editedValue}
                                    onChange={(e) => setEditedValue(e.target.value)}
                                    borderRadius={"full"}
                                    marginRight={2}
                                />
                                <IconButton
                                    bg={"none"}
                                    icon={<FaSave />}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleSaveEdit(category)}
                                    borderRadius={"full"}
                                />
                                <TagCloseButton color="white" onClick={() => setEditingCategory(null)} />
                            </>
                        ) : (
                            <>
                                <TagLabel>{category.categories}</TagLabel>
                                <TagCloseButton color="white" onClick={() => handleDeleteCategory(category)} />
                                <IconButton
                                    icon={<FaEdit />}
                                    bg={"none"}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleEditCategory(category)}
                                    borderRadius={"full"}
                                    ml={2}
                                />
                            </>
                        )}
                    </Tag>
                ))}
            </Flex>
        </Box>
    );
};

export default CategorySettings;
