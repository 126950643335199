import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
} from '@chakra-ui/react';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DogsComponent from '../DogsComponent';
import ChatWithAdmin from '../../pages/ChatWithAdmin';

const currencyIcons = {
  INR: '₹',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CAD: '$',
  AUD: '$',
};

const MyOrders = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [time, setTime] = useState('');
  const navigate = useNavigate();
  const userCurrency = localStorage.getItem('userCurrency');
  const userToken = localStorage.getItem('userToken');

  const getdata = async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get('https://api.digiblocks.tech/order', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      const paidOrders = response.data.filter(order => order.isPaid === true);
      setAllOrders(paidOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString();
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      {userToken ? (
        <>
          <Flex m="auto" w={['90%', '', '70%']} align="center" justify="space-between">
            <Flex>
              <Heading fontFamily='DM Serif Display' textAlign="center" my={8}>
                My Orders 📦
              </Heading>
            </Flex>
            <Flex alignItems={"center"} direction={["column", "column", "row"]}>
              <ChatWithAdmin />
              <Button onClick={() => navigate('/my-account')}
                size="sm"
                borderRadius="none"
                colorScheme="green"
                bg="#6FA82F"
                width="150px"
                ml={"2"}
                _hover={{ bg: "black", color: "white" }}
              >
                <ArrowLeftIcon />&nbsp; My Account
              </Button>
            </Flex>

          </Flex>
          {allOrders.length === 0 ? (
            <Box textAlign="center">
              <Text fontWeight="bold" fontSize="xl">No orders yet 😔</Text>
              <DogsComponent />
              <Button colorScheme="green"
                _hover={{ bg: '#232424' }}
                bg={'#6FA82F'}
                fontSize={['14px', '14px', '18px']}
                p={['25px', '30px']}
                color={'white'}
                borderRadius={'0'}
                m={'auto'}
                my={'30px'}
                onClick={() => navigate('/products')}>
                Continue Shopping 🛒
              </Button>
            </Box>
          ) : (
            <Flex direction="column" gap={5}>
              {allOrders.reverse().map((order, index) => (
                <Box key={index} m="auto" w={['90%', '', '70%']}>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          onClick={() => setTime(formatTime(order.timeOfPayment))}
                          _hover={{ bg: 'rgb(256,260,252)' }}
                          bg="rgb(246,250,242)"
                          color="black"
                        >
                          <Box as="span" flex="1" textAlign="left">
                            <Flex direction={['column', 'column', 'row']} justify="space-between" p={3}>
                              <Flex direction="column" gap={3} justify={['flex-start']}>
                                <Text m={0} fontSize={['15px', '15px', '18px']} fontWeight={600}>
                                  Order ID: {order._id}
                                </Text>
                                <Text m={0} fontSize={['15px', '15px', '18px']} fontWeight={600}>
                                  Order Date: {order.timeOfPayment.toString().substring(0, 10)}
                                </Text>
                              </Flex>
                              <Flex direction="column" gap={3}>
                                <Text m={0} fontSize={['15px', '15px', '18px']} fontWeight={600}>
                                  Total Amount: {currencyIcons[order.userCurrency]}{order.finalAmount}
                                </Text>
                                <Text m={0} fontSize={['15px', '15px', '18px']} fontWeight={600}>
                                  Order Status: {order.status === 'new' ? 'Order Placed 📦' : order.status === 'inprogress' ? 'Order Shipped 🚚' : 'Delivered ✅'}
                                </Text>
                              </Flex>
                              <Box p={3} display="flex">
                                <Flex flexDirection="column" gap={8}>
                                  {order.trackingUrl && (
                                    <Button
                                      size="sm"
                                      borderRadius="none"
                                      colorScheme="green"
                                      bg="#6FA82F"
                                      _hover={{ bg: "black", color: "white" }}
                                      onClick={() => window.open(order.trackingUrl, '_self')}
                                    >
                                      Track Order 🛤️
                                    </Button>
                                  )}
                                  <Button
                                    size="sm"
                                    borderRadius="none"
                                    colorScheme="green"
                                    bg="#6FA82F"
                                    width="150px"
                                    onClick={() => navigate(`/my-account/my-orders/${order._id}`)}
                                    _hover={{ bg: "black", color: "white" }}
                                  >
                                    Invoice 🧾
                                  </Button>
                                  <Button
                                    size="sm"
                                    borderRadius="none"
                                    colorScheme="green"
                                    bg="#6FA82F"
                                    width="150px"
                                    _hover={{ bg: "black", color: "white" }}
                                  >
                                    View Details 🔍
                                  </Button>
                                </Flex>
                              </Box>
                            </Flex>
                            <AccordionIcon />
                          </Box>
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <>
                          {order.cartProducts.map((product, ind) => (
                            <Flex
                              p={3}
                              key={ind}
                              border="2px solid rgb(111, 168, 47)"
                              borderRadius="15px"
                              direction={['column', 'column', 'row']}
                              alignItems="center"
                              bg="white"
                              boxShadow="md"
                              mb={3}
                            >
                              <Box flexShrink={0}>
                                <Image
                                  h={['150px', '150px', '200px']}
                                  w={['100%', '100%', 'auto']}
                                  objectFit="cover"
                                  alt="Product"
                                  src={product.photos[0]}
                                  borderRadius="15px"
                                />
                              </Box>
                              <Flex flexDirection="column" ml={[0, 0, 3]} flex="1" mt={[3, 3, 0]}>
                                <Text fontWeight="bold" fontSize={['md', 'md', 'lg']} mb={2}>
                                  {product.title}
                                </Text>
                                <Flex alignItems="center" fontSize={['sm', 'sm', 'md']} mb={1}>
                                  <Text>Per Item Price: </Text>
                                  <Text fontWeight={600}>{currencyIcons[userCurrency]} {product[`price${userCurrency}`]}</Text>
                                </Flex>
                                <Flex alignItems="center" fontSize={['sm', 'sm', 'md']} mb={1}>
                                  <Text>Quantity: </Text>
                                  <Text fontWeight={600}>{product.quantity}</Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          ))}
                        </>
                        <Flex justifyContent="center">
                          <Button
                            bg="none"
                            _hover={{ bg: "none" }}
                            color="white"
                            textAlign="center"
                            onClick={() => { }}
                          >
                            <AccordionButton
                              size="sm"
                              textAlign="center"
                              borderRadius="none"
                              colorScheme="green"
                              bg="#6FA82F"
                              _hover={{ bg: "black", color: "white" }}
                            >
                              Close
                            </AccordionButton>
                          </Button>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              ))}
            </Flex>
          )}
        </>
      ) : (
        <>
          <Text textAlign="center" mt="4" onClick={() => navigate('/login')} cursor="pointer" fontFamily='DM Serif Display' fontSize="xl" fontWeight="bold" color="gray.600">
            Please Login First 🔒
          </Text>
          <DogsComponent />
        </>
      )}
    </>
  );
};

export default MyOrders;
